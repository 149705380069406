import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, } from '@angular/material/dialog';
import { map, Observable, shareReplay } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'angular-monorepo-checkout-coinnang-modal-error',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './checkout-coinnang-modal-error.component.html',
  styleUrl: './checkout-coinnang-modal-error.component.scss',
})
export class CheckoutCoinnangModalErrorComponent implements OnInit {

  isMobile$: Observable<boolean> = this.breakpointObserver.observe(['(max-width:767px)']).pipe(
    map((result: BreakpointState) => result.matches),
    shareReplay(1)
  )

  constructor(public matDialogRef: MatDialogRef<CheckoutCoinnangModalErrorComponent>, private readonly breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {

    this.isMobile$.subscribe(isMobile => {
      if (isMobile) {
        this.matDialogRef.updateSize('320px', '350px');
      }
    })
  }

  onCancel() {
    this.matDialogRef.close();
  }
}
