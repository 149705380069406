import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, } from '@angular/material/dialog';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'angular-monorepo-checkout-coinnang-modal-loading',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './checkout-coinnang-modal-loading.component.html',
  styleUrl: './checkout-coinnang-modal-loading.component.scss',
})
export class CheckoutCoinnangModalLoadingComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<CheckoutCoinnangModalLoadingComponent>) { }

  ngOnInit(): void {
  }

  onCancel() {
    this.matDialogRef.close();
  }
}
