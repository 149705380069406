<div class="coinnang-modal-error">
    <mat-spinner [diameter]="43"></mat-spinner>

    <h3 class="coinnang-modal-error__title">Cargando</h3>

    <p class="coinnang-modal-error__detail">
        Estamos realizando la búsqueda.
        <br />
        Gracias por tu espera.
    </p>
</div>